<template>
    <div>

        <!-- search block starts here -->
        <div class="formOptionsBox">
            <div class="searchOptionsWrap">
                <div class="searchBlock" id="module-search">
                    <form @submit.prevent="">
                        <div class="searchIconWrap">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                                <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                            </svg>
                        </div>
                        <input
                            type="text"
                            name="search"
                            id="search"
                            placeholder="Search"
                            v-model="searchedKeyword"
                            @input="search()"
                            :disabled="disable"
                        />
                    </form>
                </div>
                <div class="filterShowTabsRow" v-if="searchedKeyword">
                    <div class="filterShowTabsListSearchedQuery">
                        <p>Search results for "{{ searchedKeyword.length > 20 ? searchedKeyword.substr(0, 20) + "..." : searchedKeyword }}".</p>
                    </div>
                    <div class="filterShowTabsResetBtn">
                        <button type="button" @click.prevent="clearSearch" :disabled="disable">Clear</button>
                    </div>
                </div>
            </div>
            <div class="csvBtn" v-if="moduleName == 'email'">
                <div class="btn-group">
                    <button type="button" @click="openEmailTemplateModal()">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title/>
                            <path d="M12.69,12.06a1,1,0,0,1-1.34,0L2.87,4.35A2,2,0,0,1,4,4H20a2,2,0,0,1,1.13.35Z" fill="#fff"/>
                            <path d="M22,6.26V17a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V6.26l8.68,7.92a2,2,0,0,0,1.32.49,2,2,0,0,0,1.33-.51Z" fill="#fff"/>
                        </svg>
                        Email Templates
                    </button>
                </div>
            </div> 
        </div>
        <!-- search block ends here -->

    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name:'moduleSearch',
    data(){
        return {
            searchedKeyword:'',
            timer: undefined,
        }
    },
    props:{
        moduleName:{
            type:String,
            required: true,
            description: 'module type',
        },
        disable:{
            type:Boolean,
            default: false
        },
    },
    watch:{
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            categorySearchKeyword:'productCategories_module/keyword',
            brandsSearchKeyword:'brands_module/keyword',
            customerSearchKeyword:'customers_module/keyword',
            shippingRegionsKeyword: 'shipping_module/keyword',
            couponSearchKeyword:'coupons_module/keyword',
            pageDetailsSearchKeyword:'page_details_module/keyword',
            blogCategorySearchKeyword:'blogCategories_module/keyword',
            blogsSearchKeyword:'blogs_module/keyword',
            accountsSearchKeyword:'accounts_module/keyword',
            expensesSearchKeyword:'expenses_module/keyword',
            vendorSearchKeyword:'vendor_module/keyword',
            billsSearchKeyword:'bills_module/keyword',
            notificationsSearchedKeyword:'notifications_module/keyword',
            supportMembersSearchKeyword:'support_members_module/keyword',
            adminSearchKeyword:'admin_users_module/keyword',
            contactsSearchKeyword:'contacts_module/keyword',
            groupsSearchKeyword:'groups_module/keyword',
            emailsSearchKeyword:'emails_module/keyword',
        }),
    },
    methods:{
        search(){

            if((this.moduleName != "bill") &&
            (this.moduleName != "shipping")){

                this.$parent.resetPreview();

            }

            if(this.moduleName == 'category'){

                this.$parent.isLoadingBox = true;

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {

                if(this.searchedKeyword.length > 0){

                    let payload = {
                        keyword: this.searchedKeyword.trim(),
                    }

                    this.$store.commit('productCategories_module/update_searched_keyword',payload)
                    this.$store.dispatch('productCategories_module/fetchProductCategoriesRequest',{ size:15, page:1, loadData: 'new'})

                }else{

                    this.$store.commit('productCategories_module/remove_searched_keyword')
                    this.$store.dispatch('productCategories_module/fetchProductCategoriesRequest',{size:15, page:1, loadData: 'new'});

                }

                }, 500);

            }else if(this.moduleName == "brand"){

                this.$parent.brandsLoading = true;

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.length > 0){

                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('brands_module/update_searched_keyword',payload);
                        this.$store.dispatch('brands_module/fetchBrandsRequest',{ size:15, page:1, loadData: 'new'});

                    }else{

                        this.$store.commit('brands_module/remove_searched_keyword');
                        this.$store.dispatch('brands_module/fetchBrandsRequest',{size:15, page:1, loadData: 'new'});

                    }
                    
                }, 500)

            }else if(this.moduleName == "customer"){

                this.$parent.boxIsLoading = true;

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.length > 0){

                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('customers_module/update_searched_keyword',payload)
                        this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{ size:15, page:1, loadData:'new'})

                    }else{

                        this.$store.commit('customers_module/remove_searched_keyword')
                        this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{ size:15, page:1, loadData:'new'})

                    }

                }, 500)

            }else if(this.moduleName == "shipping"){

                this.$parent.regionsLoader = true;

                clearTimeout(this.timer);

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.length > 0){

                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('shipping_module/update_searched_keyword',payload);
                        this.$store.dispatch('shipping_module/fetchStoreRegionsRequest',{size:10, page:1, loadData: 'new'});

                    }else{

                        this.$store.commit('shipping_module/remove_searched_keyword');
                        this.$store.dispatch('shipping_module/fetchStoreRegionsRequest',{size:10, page:1, loadData: 'new'});
                    
                    }

                }, 500)

            }else if(this.moduleName == "coupon"){

                this.$parent.couponsLoading = true;

                clearTimeout(this.timer);

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.length > 0){

                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('coupons_module/update_searched_keyword',payload);
                        this.$store.dispatch('coupons_module/fetchStoreCouponsRequest',{size:11, page:1, loadData: 'new'});

                    }else{

                        this.$store.commit('coupons_module/remove_searched_keyword');
                        this.$store.dispatch('coupons_module/fetchStoreCouponsRequest',{size:11, page:1, loadData: 'new'});

                    }

                }, 500)

            }else if(this.moduleName == "page_detail"){

                this.$parent.pageDetailsLoading = true;

                clearTimeout(this.timer);

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.length > 0){

                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        };

                        this.$store.commit("page_details_module/update_searched_keyword",payload);
                        this.$store.dispatch("page_details_module/fetchStorePageDetailsRequest",{size: 10, page: 1, loadData: 'new'});

                    }else{

                        this.$store.commit("page_details_module/remove_searched_keyword");
                        this.$store.dispatch("page_details_module/fetchStorePageDetailsRequest",{size: 10, page: 1, loadData: 'new'});

                    }
                    
                }, 500);

            }else if(this.moduleName == "blog_category"){

                this.$parent.blogCategoriesLoading = true;

                clearTimeout(this.timer);

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.trim().length > 0){
                        
                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('blogCategories_module/update_searched_keyword',payload);
                        this.$store.dispatch('blogCategories_module/fetchStoreBlogCategoriesRequest',{size: 11, page: 1, loadData: 'new'});

                    }else{

                        this.$store.commit('blogCategories_module/remove_searched_keyword');
                        this.$store.dispatch('blogCategories_module/fetchStoreBlogCategoriesRequest',{size: 11, page: 1, loadData: 'new'});

                    }

                }, 500)

            }else if(this.moduleName == "blog"){
                
                this.$parent.blogsLoading = true;

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.trim().length > 0){

                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('blogs_module/update_searched_keyword',payload)
                        this.$store.dispatch('blogs_module/fetchStoreBlogsRequest',{size: 10, page: 1,loadData: 'new'});

                    }else{

                        this.$store.commit('blogs_module/remove_searched_keyword')
                        this.$store.dispatch('blogs_module/fetchStoreBlogsRequest',{size: 10, page: 1,loadData: 'new'});

                    }

                }, 500)

            }else if(this.moduleName == "account"){

                this.$parent.accountsLoading = true;

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.trim().length > 0){

                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('accounts_module/update_searched_keyword',payload)
                        this.$store.dispatch('accounts_module/fetchStoreAccountsRequest',{size:11, page:1, loadData: 'new'});

                    }else{

                        this.$store.commit('accounts_module/remove_searched_keyword')
                        this.$store.dispatch('accounts_module/fetchStoreAccountsRequest',{size:11, page:1, loadData: 'new'});

                    }

                }, 500)

            }else if(this.moduleName == "expense"){

                this.$parent.expensesLoading = true;

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {
                    
                    if(this.searchedKeyword){

                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }
                        
                        this.$store.commit('expenses_module/update_searched_keyword',payload)
                        this.$store.dispatch('expenses_module/fetchStoreExpensesRequest',{size: 12, page: 1, loadData: 'new'});

                    }else{

                        this.$store.commit('expenses_module/remove_searched_keyword')
                        this.$store.dispatch('expenses_module/fetchStoreExpensesRequest',{size: 12, page: 1, loadData: 'new'});

                    }

                }, 500)

            }else if(this.moduleName == "vendor"){

                this.$parent.vendorsLoading = true;

                clearTimeout(this.timer)

                    this.timer = setTimeout(() => {

                        if(this.searchedKeyword){

                            let payload = {
                                keyword: this.searchedKeyword.trim(),
                            }
                            
                            this.$store.commit('vendor_module/update_searched_keyword',payload)
                            this.$store.dispatch('vendor_module/fetchStoreVendorsRequest',{size:10, page:1, loadData: 'new'});

                        }else{

                            this.$store.commit('vendor_module/remove_searched_keyword')
                            this.$store.dispatch('vendor_module/fetchStoreVendorsRequest',{size:10, page:1, loadData: 'new'});

                        }

                }, 500)

            }else if(this.moduleName == "bill"){

                this.$parent.billsLoading = true;

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword){

                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('bills_module/update_searched_keyword',payload)
                        this.$store.dispatch('bills_module/fetchStoreBillsRequest',{size:10, page:1, loadData: 'new'});

                    }else{

                        this.$store.commit('bills_module/remove_searched_keyword')
                        this.$store.dispatch('bills_module/fetchStoreBillsRequest',{size:10, page:1, loadData: 'new'});

                    }

                }, 500)

            }else if(this.moduleName == "notification"){

                this.$parent.notificationsLoading = true;

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword){

                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }
                        
                        this.$store.commit('notifications_module/update_searched_keyword',payload)
                        this.$store.dispatch('notifications_module/fetchStoreNotificationsRequest',{size: 12, page: 1, loadData: 'new'});

                    }else{

                        this.$store.commit('notifications_module/remove_searched_keyword')
                        this.$store.dispatch('notifications_module/fetchStoreNotificationsRequest',{size: 12, page: 1, loadData: 'new'});

                    }

                }, 500)

            }else if(this.moduleName == "support_member"){

                this.$parent.supportMembersLoading = true;

                this.timer = setTimeout(() => {

                if(this.searchedKeyword.length > 0){

                    let payload = {
                        keyword: this.searchedKeyword.trim(),
                    }
                    this.$store.commit('support_members_module/update_searched_keyword',payload);
                    this.$store.dispatch('support_members_module/fetchStoreSupportMembersRequest',{size:10, page:1, loadData: 'new'});

                }else{

                    this.$store.commit('support_members_module/remove_searched_keyword');
                    this.$store.dispatch('support_members_module/fetchStoreSupportMembersRequest',{size:10, page:1, loadData: 'new'});

                }

                }, 500)

            }else if(this.moduleName == "user_management"){

                this.$parent.usersLoading = true;

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.length > 0){

                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('admin_users_module/update_searched_keyword',payload)
                        this.$store.dispatch('admin_users_module/fetchStoreAdminUsersRequest',{size: 10, page: 1, loadData: 'new'})

                    }else{

                        this.$store.commit('admin_users_module/remove_searched_keyword')
                        this.$store.dispatch('admin_users_module/fetchStoreAdminUsersRequest',{size: 10, page: 1, loadData: 'new'})

                    }

                }, 500)

            }else if(this.moduleName == "contact"){

                this.$parent.contactsLoading = true;

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.length > 0){
                        
                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('contacts_module/update_searched_keyword',payload);
                        this.$store.dispatch('contacts_module/fetchStoreContactsRequest',{ size:15, page:1, loadData:'new'});

                    }else{

                        this.$store.commit('contacts_module/remove_searched_keyword');
                        this.$store.dispatch('contacts_module/fetchStoreContactsRequest',{ size:15, page:1, loadData:'new'});

                    }
                    
                }, 500)

            }else if(this.moduleName == "group"){

                this.$parent.groupsLoading = true;

                clearTimeout(this.timer);

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.length > 0){
                        
                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('groups_module/update_searched_keyword',payload);
                        this.$store.dispatch('groups_module/fetchStoreGroupsRequest',{ size:15, page:1, loadData:'new'});

                    }else{

                        this.$store.commit('groups_module/remove_searched_keyword');
                        this.$store.dispatch('groups_module/fetchStoreGroupsRequest',{ size:15, page:1, loadData:'new'});

                    }
                    
                }, 500)

            }else if(this.moduleName == "email"){

                this.$parent.emailsLoading = true

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {

                    if(this.searchedKeyword.length > 0){
                        
                        let payload = {
                            keyword: this.searchedKeyword.trim(),
                        }

                        this.$store.commit('emails_module/update_searched_keyword',payload)
                        this.$store.dispatch('emails_module/fetchStoreEmailsRequest',{ size:15, page:1, loadData:'new'})

                    }else{

                        this.$store.commit('emails_module/remove_searched_keyword')
                        this.$store.dispatch('emails_module/fetchStoreEmailsRequest',{ size:15, page:1, loadData:'new'})

                    }

                }, 500)

            }

        },
        clearSearch(){

            this.searchedKeyword = ''

            this.search();

        },
        openEmailTemplateModal(){

            this.$parent.openAllEmailTemplatesModal();

        },
    },
    mounted(){

        if((this.categorySearchKeyword.length > 0) && (this.moduleName == 'category')){

            this.searchedKeyword = this.categorySearchKeyword;

        }

        if((this.brandsSearchKeyword.length > 0) && (this.moduleName == 'brand')){

            this.searchedKeyword = this.brandsSearchKeyword;

        }

        if((this.customerSearchKeyword.length > 0) && (this.moduleName == 'customer')){

            this.searchedKeyword = this.customerSearchKeyword;

        }

        if((this.shippingRegionsKeyword.length > 0) && (this.moduleName == 'shipping')){

            this.searchedKeyword = this.shippingRegionsKeyword;

        }

        if((this.couponSearchKeyword.length > 0) && (this.moduleName == 'coupon')){

            this.searchedKeyword = this.couponSearchKeyword;

        }

        if((this.pageDetailsSearchKeyword.length > 0) && (this.moduleName == 'page_detail')){

            this.searchedKeyword = this.pageDetailsSearchKeyword;

        }

        if((this.blogCategorySearchKeyword.length > 0) && (this.moduleName == 'blog_category')){

            this.searchedKeyword = this.blogCategorySearchKeyword;

        }

        if((this.blogsSearchKeyword.length > 0) && (this.moduleName == 'blog')){

            this.searchedKeyword = this.blogsSearchKeyword;

        }

        if((this.accountsSearchKeyword.length > 0) && (this.moduleName == 'account')){

            this.searchedKeyword = this.accountsSearchKeyword;

        }

        if((this.expensesSearchKeyword) && (this.moduleName == 'expense')){

            this.searchedKeyword = this.expensesSearchKeyword;

        }

        if((this.vendorSearchKeyword.length > 0) && (this.moduleName == 'vendor')){
            
            this.searchedKeyword = this.vendorSearchKeyword;

        }

        if((this.billsSearchKeyword.length > 0) && (this.moduleName == 'bill')){
        
            this.searchedKeyword = this.billsSearchKeyword;
        
        }

        if((this.notificationsSearchedKeyword) && (this.moduleName == 'notification')){
            
            this.searchedKeyword = this.notificationsSearchedKeyword;

        }

        if((this.supportMembersSearchKeyword.length > 0) && (this.moduleName == 'support_member')){

            this.searchedKeyword = this.supportMembersSearchKeyword;

        }

        if((this.adminSearchKeyword.length > 0) && (this.moduleName == 'user_management')){

            this.searchedKeyword = this.adminSearchKeyword;

        }

        if((this.contactsSearchKeyword.length > 0) && (this.moduleName == 'contact')){

            this.searchedKeyword = this.contactsSearchKeyword;

        }

        if((this.groupsSearchKeyword.length > 0) && (this.moduleName == 'group')){

            this.searchedKeyword = this.groupsSearchKeyword;

        }

        if((this.emailsSearchKeyword.length > 0) && (this.moduleName == 'email')){

            this.searchedKeyword = this.emailsSearchKeyword;

        }

    },
    beforeMount(){

    },
}
</script>

<style>

</style>